<template>
  <Layout :items="items">
    <PageHeader title="Organization List">
      <template #button>
        <div
          v-if="userRole <= 1"
          class="flex-shrink-0 text-end"
        >
          <button
            class="btn btn-primary w-100"
            data-bs-toggle="modal"
            href="#organization-form-modal"
            @click="selectedOrganization = {}"
          >
            Create Organization
          </button>
        </div>
      </template>
    </PageHeader>
    <b-row>
      <b-col 
        lg
        class="px-0 px-lg-2"
      >         
        <div>
          <Pagination
            :meta-data="organizationsMeta"
            @page-change="handlePageChange"
            @per-page-change="handlePerPageChange"
          >
            <template #button>
              <b-col>
                <SearchBar
                  placeholder="Search Organization"
                  @do-search="(val) => filter.keyword = val"
                  @reset-search="filter.keyword = null"
                />
              </b-col>
            </template>
            <template #table>
              <DataTable
                :fields="tableFields"
                :items="organizations"
                :allow-local-sort="true"
                :is-loading="isLoading"
              >
                <template #cell(name)="{ row }">
                  <router-link
                    :to="`/organization/${row.item.id}`"
                    class="link-item-table custom-link"
                    v-html="highlightString(row.item.name, filter.keyword)"
                  />
                </template>
                <template #cell(timezone)="{ row }">
                  {{ parseTimezone(row.item.timezone) }}
                </template>
                <template #cell(action)="{ row }">
                  <div class="d-flex flex-shrink-0">
                    <b-button
                      v-if="userRole <= 0"
                      variant="outline-info"
                      class="me-2"
                      data-bs-toggle="modal"
                      href="#organization-form-modal"
                      @click="selectedOrganization = row.item"
                    >
                      <i class="ri-edit-2-line" />
                    </b-button>
                    <b-button
                      v-if="userRole <= 0"
                      variant="outline-danger"
                      data-bs-toggle="modal"
                      href="#delete-modal"
                      @click="selectedOrganization = row.item"
                    >
                      <i class="ri-delete-bin-line" />
                    </b-button>
                  </div>
                </template>
              </DataTable>
              <div
                v-if="!organizations.length"
                class="text-small text-center mb-3"
              >
                {{ filter.keyword ? 'Sorry, no organizations match your search.' : 'Oops! There is no organization yet.' }}
              </div>
            </template>
          </Pagination>
        </div>
      </b-col>
    </b-row>
    <OrganizationFormModal
      v-if="userRole <= 0"
      :organization-detail="selectedOrganization"
      @update-data="getOrganizations"
    />
    <DeleteModal 
      v-if="userRole <= 0"
      entity="Organization"
      :name="selectedOrganization.name ?? ''"
      @delete-data="doDelete"
    />
  </Layout>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/PageHeader";
  import DeleteModal from '@/components/DeleteModal.vue';

  import { highlightString } from '@/utils/helper';

  import OrganizationFormModal from '@/components/organization/OrganizationFormModal.vue';
  import Pagination from '@/components/Pagination.vue';
  import SearchBar from '@/components/SearchBar.vue';
  import DataTable from '@/components/DataTable.vue';
  
  import { useToast } from 'vue-toastification';
  import ToastDefault from '@/components/ToastDefault.vue';

  import { timezoneOptions } from '@/utils/constant';

  export default {
    components: {
      Layout,
      DeleteModal,
      PageHeader,
      OrganizationFormModal,
      Pagination,
      SearchBar,
      DataTable
    },
    setup () {
      const toast = useToast();
      return {
        toast,
      }
    },
    data() {
      return {
        title: "All Organizations",
        items: [{
            text: "Organization List",
            href: "/organization",
          },
        ],
        isLoading: false,
        organizations: [],
        organizationsMeta: {},
        tableFields: [{
            key: 'name',
            label: 'Name',
            thStyle: {
              minWidth: '15rem',
            },
            class: 'text-break fit',
            sortable: true,
          }, {
            key: 'description',
            label: 'Description',
            thStyle: {
              minWidth: '15rem',
            },
            class: 'text-break',
            sortable: true,
          }, {
            key: 'timezone',
            label: 'Timezone',
            class: 'text-break',
            sortable: true,
          }, {
            key: 'action',
            label: 'Action',
            class: 'text-break fit',
            thStyle: {
              minWidth: '4rem',
            },
          },
        ],
        filter: {
          keyword: '',
          per_page: 10,
          page: 1,
        },
        selectedOrganization: {},
      };
    },
    computed: {
      ...mapGetters('organization', ['userRole']),
    },
    watch: {
      filter: {
        deep: true,
        immediate: true,
        handler() {
          this.$router.push({ query: { ...this.filter } })
          this.getOrganizations();
        }
      }
    },
    methods: {
      highlightString,
      async getOrganizations() {
        try {
          this.isLoading = true;
          
          const { data } = await this.$api.getOrganizations({ ...this.filter });

          this.organizations = data.data;
          this.organizationsMeta = data.meta;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      async doDelete() {
        try {
          await this.$api.deleteOrganization(this.selectedOrganization.id);           
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Deleted successfully',
              icon: 'ri-check-double-line',
            },
          };
          this.toast(toastContent);
          this.getOrganizations()
        } catch (error) {
          console.log(error);

          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'danger',
              text: 'Process Failed',
            },
          };
          this.toast(toastContent);
        }
      },
      handlePageChange(page) {
        this.filter.page = page
      },
      handlePerPageChange(perPage) {
        this.filter.page = 1
        this.filter.per_page = perPage
      },
      parseTimezone(timezone) {
        return timezoneOptions.find(item => item.value == timezone).label ?? timezone
      }
    },
  };
</script>
